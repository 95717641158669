<template>
  <section>
    <template>


      <b-row>
        <b-col cols="8" xs="12">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col cols="12">
                  <b-card>
                    <b-row>
                      <b-col md="10" lg="10" xs="12">
                        <b-breadcrumb class="breadcrumb-chevron pl-0">
                          <template v-if="subtask?.title">
                            <b-breadcrumb-item v-on:click="onShowBoardDetails">
                              {{ subtask?.milestoneTaskBoard?.data?.title }}
                            </b-breadcrumb-item>

                            <b-breadcrumb-item
                                v-on:click="
                              onShowTaskDetails(
                                subtask?.task?.data?.short_title
                              )
                            "
                            >
                              {{ subtask?.task?.data?.title }} (
                              {{ subtask?.task?.data?.short_title }})
                            </b-breadcrumb-item>
                            <b-breadcrumb-item active>
                              {{ subtask?.short_title }}
                            </b-breadcrumb-item>
                          </template>
                        </b-breadcrumb>
                        <template v-if="subtask?.title">
                          <div>
                            <b-card-text>
                              <h2>
                                <template v-if="+subtask?.sequence_num">
                                  <b-badge variant="light-warning">{{
                                      subtask?.sequence_num
                                    }}</b-badge>
                                </template>

                                {{ subtask?.title }}
                                <feather-icon
                                    icon="CopyIcon"
                                    @click="copySubTaskUrlToClipboard"
                                />
                              </h2>
                              <span
                                  v-if="showCopiedMessage"
                                  class="copied-message"
                              >
                              <b-badge variant="success" class="badge-glow">
                                Link Copied!
                              </b-badge>
                            </span>
                            </b-card-text>
                          </div>
                        </template></b-col
                      >

                      <template
                          v-if="
                        $permissionAbility(
                          MILESTONE_BOARD_SUB_TASK_EDIT,
                          permissions
                        )
                      "
                      >
                        <b-col cols="2">
                          <template v-if="subtask?.title">
                            <div class="d-flex justify-content-end">
                              <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="primary"
                                  v-on:click="showSubTaskModal(subtask)"
                                  class="btn-sm"
                              >
                                <feather-icon icon="EditIcon" class="mr-50" />
                                <span class="align-middle">Edit</span>
                              </b-button>
                            </div>
                          </template></b-col
                        >
                      </template>
                    </b-row>
                    <b-row class="mt-1">
                      <b-col md="6" lg="6" xs="12">
                        <b-button-group class="button-padding">
                          <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="primary"
                              v-on:click="showSubTaskLinkForm"
                              class="btn-sm"
                          >
                            <feather-icon icon="LinkIcon" class="mr-50" />
                            Link Sub Task
                          </b-button>
                        </b-button-group>
                      </b-col>

                      <template v-if="isSubTaskLinkFormLoaded">
                        <b-col md="12" lg="12" xs="12" class="mt-1 mb-1">
                          <validation-observer ref="subTaskLinkAdd">
                            <b-form v-on:submit.prevent="subTaskLinkAddForm">
                              <b-row
                              ><b-col md="8" lg="8" xs="12">
                                <ValidationProvider
                                    name="sub_task_title"
                                    v-slot="{ errors }"
                                    vid="sub_task_list_id"
                                    rules="required"
                                >
                                  <v-select
                                      id="sub_task_title"
                                      placeholder="Search Sub task and link here"
                                      class="custom-font"
                                      v-model="sub_task_list_id"
                                      :options="subtasksLinkOption"
                                      label="name"
                                  />
                                  <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                </ValidationProvider>
                              </b-col>
                                <b-col md="4" lg="4" xs="12">
                                  <template v-if="isSubTaskLinkLoading">
                                    <b-button
                                        class="float-right"
                                        variant="primary"
                                        disabled
                                    >
                                      <b-spinner small />
                                      Loading...
                                    </b-button> </template
                                  ><template v-else>
                                  <b-button
                                      class="float-right btn-sm"
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      variant="primary"
                                      v-on:click="closeSubTaskLinkForm"
                                  >
                                    Cancel
                                  </b-button>
                                  <b-button
                                      type="submit"
                                      class="float-right mr-1 btn-sm"
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      variant="primary"
                                  >
                                    Add
                                  </b-button>
                                </template>
                                </b-col>
                              </b-row>
                            </b-form>
                          </validation-observer>
                        </b-col>
                      </template>
                      <b-col md="12" lg="12" xs="12">
                        <h5>
                          {{
                            subtaskLinkList.length > 0
                                ? "Linked SubTask List:"
                                : ""
                          }}
                        </h5>
                        <b-list-group>
                          <b-list-group-item
                              class="d-flex justify-content-between align-items-center"
                              v-for="subtaskList in subtaskLinkList"
                              :key="subtaskList?.id"
                          >
                            <b-link
                                v-on:click="
                              onShowSubTask(subtaskList?.task_subtask_id)
                            "
                            >{{ subtaskList?.task_subtask_title }}</b-link
                            >

                            <div
                                class="custom-search d-flex align-items-center justify-content-end"
                            >
                              <b-button
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="danger"
                                  class="btn-sm"
                                  v-on:click="onDeleteLinkSubTask(subtaskList?.id)"
                              >
                                <feather-icon icon="TrashIcon" />
                              </b-button>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="subtask?.description">
                      <b-col cols="12">
                        <div class="mt-2">
                          <template >
                            <h5 class="text-capitalize">Description:</h5>

                            <b-card-text>
                              <div v-html="subtask?.description"></div>
                            </b-card-text>
                          </template>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="12">
            <b-card>
              <b-tabs>
                <b-tab title="Comments">
                  <div active class="comment-scrollable">
                    <b-card-text>
                      <b-row>
                        <b-col md="12" xl="12" class="mb-1">
                          <!-- comments -->
                          <template v-if="isShowCommentModal == false">
                            <div class="mx-2 my-1">
                              <b-form-input
                                  id="type"
                                  type="text"
                                  placeholder="Add Comment...."
                                  class="custom-font"
                                  v-on:click="showCommentModal"
                                  autocomplete="off"
                              />
                            </div>
                          </template>

                          <template
                              v-if="
                            taskEditClicked == false &&
                            isShowCommentModal == true
                          "
                          >
                            <div class="mt-1 mx-2">
                              <validation-observer ref="addCommentFormValidation">
                                <b-form v-on:submit.prevent="addCommentForm">
                                  <b-form-group>
                                    <ValidationProvider
                                        #default="{ errors }"
                                        name="description"
                                        vid="description"
                                        rules="required"
                                    >
                                      <quill-editor
                                          v-model="commentDescription"
                                          :options="editorOption"
                                          :style="{
                                        width: '98% !important',
                                      }"
                                      >
                                        <div id="toolbar" slot="toolbar">
                                          <!-- Add a bold button -->
                                          <span class="ql-formats">
                                          <button class="ql-bold">Bold</button>
                                          <button class="ql-italic">
                                            Italic
                                          </button>
                                          <button class="ql-underline">
                                            Underline
                                          </button>
                                          <button class="ql-strike">
                                            Strike
                                          </button>
                                          <button
                                              class="ql-blockquote"
                                          ></button>
                                          <button
                                              class="ql-list"
                                              value="ordered"
                                          ></button>
                                          <button
                                              class="ql-list"
                                              value="bullet"
                                          ></button>
                                          <button
                                              class="ql-script"
                                              value="sub"
                                          ></button>
                                          <button
                                              class="ql-script"
                                              value="super"
                                          ></button>
                                          <button
                                              class="ql-indent"
                                              value="-1"
                                          ></button>
                                          <button
                                              class="ql-indent"
                                              value="+1"
                                          ></button>
                                          <button
                                              class="ql-direction"
                                              value="rtl"
                                          ></button>
                                          <button
                                              class="ql-align"
                                              value=""
                                          ></button>
                                          <button
                                              class="ql-align"
                                              value="center"
                                          ></button>
                                          <button
                                              class="ql-align"
                                              value="right"
                                          ></button>
                                          <button
                                              class="ql-align"
                                              value="justify"
                                          ></button>
                                          <select class="ql-color"></select>
                                          <select
                                              class="ql-background"
                                          ></select>
                                          <select class="ql-size">
                                            <option value="small" />

                                            <option selected />
                                            <option value="large" />
                                            <option value="huge" />
                                          </select>

                                          <select class="ql-font"></select>
                                          <select class="ql-header">
                                            <option value="1">Heading 1</option>
                                            <option value="2">Heading 2</option>
                                            <option value="3">Heading 3</option>
                                            <option value="4">Heading 4</option>
                                            <option value="5">Heading 5</option>
                                            <option value="6">Heading 6</option>
                                            <option selected>Normal</option>
                                          </select>

                                          <button class="ql-link"></button>
                                        </span>
                                        </div>
                                      </quill-editor>

                                      <small class="text-danger">{{
                                          errors[0]
                                        }}</small>
                                    </ValidationProvider>
                                  </b-form-group>
                                  <template v-if="isLoading">
                                    <b-button
                                        size="sm"
                                        variant="primary"
                                        disabled
                                    >
                                      <b-spinner small />
                                      Loading...
                                    </b-button>
                                  </template>
                                  <template v-else>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        size="sm"
                                        class="mr-1"
                                        variant="primary"
                                        type="submit"
                                    >
                                      Post Comment
                                    </b-button>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        size="sm"
                                        variant="primary"
                                        v-on:click="hiddenCommentModal"
                                    >
                                      Cancel
                                    </b-button>
                                  </template>
                                </b-form>
                              </validation-observer>
                            </div>
                          </template>

                          <!-- comments -->
                          <div
                              v-for="(comment, index) in commentList"
                              :key="index"
                              class="d-flex align-items-start mx-2 my-1"
                          >
                            <b-avatar
                                :src="comment?.createdBy?.data?.avatar === '' ? '/avatar.svg' : comment?.createdBy?.data?.avatar"
                                size="34"
                                class="mt-25 mr-75"
                            />
                            <div class="profile-user-info w-100">
                              <div
                                  class="d-flex align-items-center justify-content-between"
                              >
                                <h6 class="mb-0">
                                  {{ comment?.createdBy?.data?.name }}
                                </h6>
                              </div>
                              <div>
                                <template
                                    v-if="
                                  isShowEditCommentModal == true &&
                                  editCommentModalIndex == index
                                "
                                >
                                  <div class="mt-1">
                                    <validation-observer
                                        ref="editCommentFormValidation"
                                    >
                                      <b-form
                                          v-on:submit.prevent="editCommentForm"
                                      >
                                        <b-form-group>
                                          <ValidationProvider
                                              #default="{ errors }"
                                              name="description"
                                              vid="description"
                                              rules="required"
                                          >
                                            <quill-editor
                                                v-model="commentDescription"
                                                :options="editorOption"
                                                :style="{
                                              width: '98% !important',
                                            }"
                                            >
                                              <div id="toolbar" slot="toolbar">
                                                <!-- Add a bold button -->
                                                <span class="ql-formats">
                                                <button class="ql-bold">
                                                  Bold
                                                </button>
                                                <button class="ql-italic">
                                                  Italic
                                                </button>
                                                <button class="ql-underline">
                                                  Underline
                                                </button>
                                                <button class="ql-strike">
                                                  Strike
                                                </button>
                                                <button
                                                    class="ql-blockquote"
                                                ></button>
                                                <button
                                                    class="ql-list"
                                                    value="ordered"
                                                ></button>
                                                <button
                                                    class="ql-list"
                                                    value="bullet"
                                                ></button>
                                                <button
                                                    class="ql-script"
                                                    value="sub"
                                                ></button>
                                                <button
                                                    class="ql-script"
                                                    value="super"
                                                ></button>
                                                <button
                                                    class="ql-indent"
                                                    value="-1"
                                                ></button>
                                                <button
                                                    class="ql-indent"
                                                    value="+1"
                                                ></button>
                                                <button
                                                    class="ql-direction"
                                                    value="rtl"
                                                ></button>
                                                <button
                                                    class="ql-align"
                                                    value=""
                                                ></button>
                                                <button
                                                    class="ql-align"
                                                    value="center"
                                                ></button>
                                                <button
                                                    class="ql-align"
                                                    value="right"
                                                ></button>
                                                <button
                                                    class="ql-align"
                                                    value="justify"
                                                ></button>
                                                <select
                                                    class="ql-color"
                                                ></select>
                                                <select
                                                    class="ql-background"
                                                ></select>
                                                <select class="ql-size">
                                                  <option value="small" />

                                                  <option selected />
                                                  <option value="large" />
                                                  <option value="huge" />
                                                </select>

                                                <select
                                                    class="ql-font"
                                                ></select>
                                                <select class="ql-header">
                                                  <option value="1">
                                                    Heading 1
                                                  </option>
                                                  <option value="2">
                                                    Heading 2
                                                  </option>
                                                  <option value="3">
                                                    Heading 3
                                                  </option>
                                                  <option value="4">
                                                    Heading 4
                                                  </option>
                                                  <option value="5">
                                                    Heading 5
                                                  </option>
                                                  <option value="6">
                                                    Heading 6
                                                  </option>
                                                  <option selected>
                                                    Normal
                                                  </option>
                                                </select>

                                                <button
                                                    class="ql-link"
                                                ></button>
                                              </span>
                                              </div>
                                            </quill-editor>

                                            <small class="text-danger">{{
                                                errors[0]
                                              }}</small>
                                          </ValidationProvider>
                                        </b-form-group>
                                        <template v-if="isLoading">
                                          <b-button
                                              size="sm"
                                              variant="primary"
                                              disabled
                                          >
                                            <b-spinner small />
                                            Loading...
                                          </b-button>
                                        </template>
                                        <template v-else>
                                          <b-button
                                              v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                          "
                                              size="sm"
                                              class="mr-1"
                                              variant="primary"
                                              type="submit"
                                          >
                                            Submit
                                          </b-button>
                                          <b-button
                                              v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                          "
                                              size="sm"
                                              variant="primary"
                                              v-on:click="hiddenEditCommentModal"
                                          >
                                            Cancel
                                          </b-button>
                                        </template>
                                      </b-form>
                                    </validation-observer>
                                  </div>
                                </template>
                                <template v-else>
                                  <small v-html="comment?.description"> </small>
                                  <small
                                  ><span class="mr-1"
                                  ><b-link
                                      v-if="
                                        authUser?.id ==
                                        comment?.createdBy?.data?.id
                                      "
                                      v-on:click="
                                        onShowEditComment(index, comment)
                                      "
                                  >Edit</b-link
                                  ></span
                                  ><span class="mr-1"
                                  ><b-link
                                      v-if="
                                        authUser?.id ==
                                        comment?.createdBy?.data?.id
                                      "
                                      v-on:click="onCommentDelete(comment?.id)"
                                  >Delete</b-link
                                  ></span
                                  ></small
                                  >
                                </template>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>

        </b-col>
        <b-col cols="4" xs="12">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col cols="12">
                  <b-card>
                    <b-row>
                      <b-col md="6" lg="6" xs="12">
                        <h5 class="text-capitalize">Assign User</h5>
                        <div>
                        <span
                            v-for="(member, index) in subtask?.team?.data"
                            :key="index"
                        >
                          <template v-if="index <= 2">
                            <b-avatar :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar" size="26"
                            /></template>
                        </span>
                          <span v-if="subtask?.team?.data.length > 3">
                          + {{ subtask?.team?.data.length - 3 }} more
                        </span>
                          <b-button
                              variant="outline-primary"
                              class="btn-icon rounded-circle btn-sm ml-1"
                              v-on:click="showAddMemberModal(subtask)"
                              :disabled="
                            $permissionAbility(
                              MILESTONE_BOARD_SUB_TASK_EDIT,
                              permissions
                            )
                              ? false
                              : true
                          "
                          >
                            <feather-icon icon="UserPlusIcon" />
                          </b-button>
                        </div>
                      </b-col>
                      <b-col md="6" lg="6" xs="12">
                        <h5 class="text-capitalize">Phase</h5>

                        <template v-if="subtask?.phase?.data?.title">
                          <b-dropdown
                              no-caret
                              variant="outline-primary"
                              :text="subtask?.phase?.data?.title"
                              style="padding: 0"
                              size="sm"
                          >
                            <b-dropdown-item
                                v-for="phase in subtaskPhaseOptions"
                                :key="phase?.id"
                                v-on:click="updatePhase(phase)"
                            >
                              {{ phase?.title }}
                            </b-dropdown-item>
                            <b-dropdown-divider />
                            <b-dropdown-item v-on:click="onShowPhase">
                              <feather-icon icon="PlusCircleIcon" /> Add More
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>
                      </b-col>
                      <b-col md="6" lg="6" xs="12">
                        <div class="mt-2">
                          <h5 class="text-capitalize">Type</h5>
                          <b-card-text>
                            <template v-if="subtask?.type">
                              <template
                                  v-if="
                                subtask?.type == 'Task' ||
                                subtask?.type == 'Subtask' ||
                                subtask?.type == 'General'
                              "
                              >
                                <b-badge variant="light-success" class="mr-1">
                                  {{ subtask?.type }}
                                </b-badge>
                              </template>
                              <template
                                  v-else-if="
                                subtask?.type == 'Bug' ||
                                subtask?.type == 'Issue'
                              "
                              >
                                <b-badge variant="light-danger" class="mr-1">
                                  {{ subtask?.type }}
                                </b-badge>
                              </template>
                            </template>

                            <template>
                              <template v-if="subtask?.sequence_num > 0">
                                <b-badge variant="light-warning" class="mr-1">
                                  Sequential
                                </b-badge>
                              </template>
                              <template v-else>
                                <b-badge variant="light-primary" class="mr-1">
                                  Non Sequential
                                </b-badge>
                              </template>
                            </template>
                          </b-card-text>
                        </div>
                      </b-col>

                      <b-col md="6" lg="6" xs="12">
                        <div class="mt-2">
                          <h5 class="text-capitalize">Priority</h5>
                          <template v-if="subtask?.priority === 'high'">
                            <b-card-text>
                              <b-badge variant="light-danger"> High </b-badge>
                            </b-card-text>
                          </template>
                          <template v-else-if="subtask?.priority === 'low'">
                            <b-card-text>
                              <b-badge variant="light-success"> Low </b-badge>
                            </b-card-text>
                          </template>
                          <template v-else-if="subtask?.priority === 'medium'">
                            <b-card-text>
                              <b-badge variant="light-info"> Medium </b-badge>
                            </b-card-text>
                          </template>
                          <template v-else>
                            <b-card-text>
                              <b-badge variant="light-primary"> Normal </b-badge>
                            </b-card-text>
                          </template>
                        </div>
                      </b-col>

                      <b-col md="6" lg="6" xs="12">
                        <div class="mt-2">
                          <h5 class="text-capitalize">Status</h5>
                          <template v-if="subtask?.is_completed">
                            <b-card-text>
                              <b-badge variant="light-success">
                                Completed
                              </b-badge>
                            </b-card-text>
                          </template>
                          <template v-else>
                            <b-card-text>
                              <b-badge variant="light-danger">
                                Not Completed
                              </b-badge>
                            </b-card-text>
                          </template>
                        </div>
                      </b-col>

                      <b-col md="6" lg="6" xs="12">
                        <div class="mt-2">
                          <h5 class="text-capitalize">Estimation Point</h5>
                          <template v-if="subtask?.point">
                            <b-card-text>
                              <b-badge variant="light-primary">
                                {{ subtask?.point }}
                              </b-badge>
                            </b-card-text>
                          </template>
                          <template v-else>
                            <b-card-text>
                              <b-badge variant="light-danger"> N/A </b-badge>
                            </b-card-text>
                          </template>
                        </div>
                      </b-col>

                      <b-col md="6" lg="6" xs="12">
                        <div class="mt-2">
                          <h5 class="text-capitalize">Assigned Start Date</h5>
                          <template v-if="subtask?.start_date">
                            <b-card-text>
                              {{ formatDate(subtask?.start_date) }}
                            </b-card-text>
                          </template>
                          <template v-else>
                            <b-card-text>
                              <b-badge variant="light-danger"> N/A </b-badge>
                            </b-card-text>
                          </template>
                        </div>
                      </b-col>
                      <b-col md="6" lg="6" xs="12">
                        <div class="mt-2">
                          <h5 class="text-capitalize">Actual Start Date</h5>
                          <template v-if="subtask?.actual_start_date">
                            <b-card-text>
                              {{ formatDate(subtask?.actual_start_date) }}
                            </b-card-text>
                          </template>
                          <template v-else>
                            <b-card-text>
                              <b-badge variant="light-danger"> N/A </b-badge>
                            </b-card-text>
                          </template>
                        </div>
                      </b-col>
                      <b-col md="6" lg="6" xs="12">
                        <div class="mt-2">
                          <h5 class="text-capitalize">Assigned End Date</h5>
                          <template v-if="subtask?.assigned_end_date">
                            <b-card-text>
                              {{ formatDate(subtask?.assigned_end_date) }}
                            </b-card-text>
                          </template>
                          <template v-else>
                            <b-card-text>
                              <b-badge variant="light-danger"> N/A </b-badge>
                            </b-card-text>
                          </template>
                        </div>
                      </b-col>
                      <b-col md="6" lg="6" xs="12">
                        <div class="mt-2">
                          <h5 class="text-capitalize">Actual End Date</h5>
                          <template v-if="subtask?.actual_end_date">
                            <b-card-text>
                              {{ formatDate(subtask?.actual_end_date) }}
                            </b-card-text>
                          </template>
                          <template v-else>
                            <b-card-text>
                              <b-badge variant="light-danger"> N/A </b-badge>
                            </b-card-text>
                          </template>
                        </div>
                      </b-col>

                      <b-col md="6" lg="6" xs="12" v-if="subtask?.wordcount">
                        <div class="mt-2">
                          <h5 class="text-capitalize">Word Count</h5>
                          <template>
                            <b-card-text>
                              <b-badge variant="light-primary">
                                {{ subtask?.wordcount }}
                              </b-badge>
                            </b-card-text>
                          </template>
                        </div>
                      </b-col>
                      <b-col md="6" lg="6" xs="12" v-if="subtask?.contentlink">
                        <div class="mt-2">
                          <h5 class="text-capitalize">Content Link</h5>
                          <template v-if="subtask?.contentlink">
                            <b-card-text>
                              <b-badge variant="primary" class="badge-glow">
                                <a :href="subtask.contentlink" target="_blank"
                                >View Content</a
                                >
                              </b-badge>
                            </b-card-text>
                          </template>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="12">
            <b-card-code>
              <b-tabs>
                <b-tab title="Activity Log">
                  <div class="log-scrollable">
                    <b-card>
                      <app-timeline>
                        <app-timeline-item
                            v-for="(log, index) in subTaskActivityLog"
                            :key="index"
                            :variant="setLogVariant(log)"
                        >
                          <div
                              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                          >
                            <h6 v-html="formatLogTitle(log)"></h6>
                            <small class="text-muted">{{
                                formatDateTime(log?.created_at)
                              }}</small>
                          </div>
                          <p v-html="formatLogDescription(log)"></p>
                        </app-timeline-item>
                      </app-timeline>
                    </b-card>
                  </div>
                </b-tab>
                <b-tab
                    title="Phase Duration Log"
                    v-on:click="loadTimeDurationLog"
                >
                  <div class="log-scrollable">
                    <b-card>
                      <template v-if="phaseChangeLog.length > 0">
                        <app-timeline>
                          <!-- 12 INVOICES HAVE BEEN PAID -->
                          <app-timeline-item
                              v-for="(log, index) in phaseChangeLog"
                              :key="index"
                              :variant="setLogVariant(log)"
                          >
                            <div
                                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                            >
                              <h6 v-html="formatPhaseLogTitle(log)"></h6>
                              <small class="text-muted">{{
                                  formatDateTime(log?.created_at)
                                }}</small>
                            </div>
                            <p
                                v-html="
                              formatPhaseLogDescription(
                                log?.created_at,
                                nextPhaseChangeStartTime[index]
                              )
                            "
                            ></p>
                          </app-timeline-item>
                        </app-timeline>
                      </template>
                    </b-card>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card-code>
          </b-col>
        </b-col>
      </b-row>

      <div>
        <b-modal
          id="modal-sub-task-board-phase-task"
          centered
          title="Edit Sub-Task Information"
          hide-footer
          @hidden="hiddenSubTaskModal"
          size="lg"
          no-close-on-backdrop
        >
          <validation-observer ref="subtaskEdit">
            <b-form v-on:submit.prevent="subtaskEditForm">
              <b-row>
                <b-col md="12" lg="12" xs="12">
                  <b-form-group label="Title *" label-for="title">
                    <ValidationProvider
                      name="title"
                      v-slot="{ errors }"
                      vid="title"
                      rules="required"
                    >
                      <b-form-input
                        id="title"
                        type="text"
                        class="custom-font"
                        v-model="title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter sub task title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" lg="6" xs="12">
                  <b-form-group label="Type " label-for="type">
                    <ValidationProvider
                      name="type"
                      v-slot="{ errors }"
                      vid="type"
                    >
                      <v-select
                        id="type"
                        v-model="type"
                        :options="taskSubtaskTypeOptionConstant"
                        :reduce="(option) => option.value"
                        placeholder="Select task type"
                        label="name"
                        class="custom-font"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col md="6" lg="6" xs="12">
                  <b-form-group label="Priority" label-for="priority">
                    <ValidationProvider
                      name="priority"
                      v-slot="{ errors }"
                      vid="priority"
                    >
                      <v-select
                        id="priority"
                        v-model="selectPriorityType"
                        :options="priorityOptionConstants"
                        :reduce="(option) => option.value"
                        placeholder="Select sub task priority"
                        label="name"
                        class="custom-font"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Description" label-for="description">
                    <ValidationProvider
                      #default="{ errors }"
                      name="description"
                      vid="description"
                    >
                      <template v-if="taskEditClicked">
                        <quill-editor
                          v-model="description"
                          :options="editorOption"
                          :style="{
                            height: '13rem !important',
                            paddingBottom: '5rem !important',
                          }"
                        >
                          <div id="toolbar" slot="toolbar">
                            <!-- Add a bold button -->
                            <span class="ql-formats">
                              <button class="ql-bold">Bold</button>
                              <button class="ql-italic">Italic</button>
                              <button class="ql-underline">Underline</button>
                              <button class="ql-strike">Strike</button>
                              <button class="ql-blockquote"></button>
                              <button class="ql-list" value="ordered"></button>
                              <button class="ql-list" value="bullet"></button>
                              <button class="ql-script" value="sub"></button>
                              <button class="ql-script" value="super"></button>
                              <button class="ql-indent" value="-1"></button>
                              <button class="ql-indent" value="+1"></button>
                              <button class="ql-direction" value="rtl"></button>
                              <button class="ql-align" value=""></button>
                              <button class="ql-align" value="center"></button>
                              <button class="ql-align" value="right"></button>
                              <button class="ql-align" value="justify"></button>
                              <select class="ql-color"></select>
                              <select class="ql-background"></select>
                              <select class="ql-size">
                                <option value="small" />

                                <option selected />
                                <option value="large" />
                                <option value="huge" />
                              </select>

                              <select class="ql-font"></select>
                              <select class="ql-header">
                                <option value="1">Heading 1</option>
                                <option value="2">Heading 2</option>
                                <option value="3">Heading 3</option>
                                <option value="4">Heading 4</option>
                                <option value="5">Heading 5</option>
                                <option value="6">Heading 6</option>
                                <option selected>Normal</option>
                              </select>

                              <button class="ql-link"></button>
                            </span>
                          </div>
                        </quill-editor>
                      </template>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <template v-if="previousFiles.length > 0">
                <b-row class="mb-2">
                  <b-col>
                    <div class="scrollable-file-container">
                      <div class="uploaded-files mb-2 scrollable-file-content">
                        <div
                          v-for="(item, index) in previousFiles"
                          :key="index"
                          class="file-preview"
                        >
                          <b-row class="mr-1">
                            <b-col md="8" lg="8" xs="12">
                              <div v-b-tooltip.hover.bottom="item.file_name">
                                <template v-if="isPreviousFileImage(item)">
                                  <img
                                    :src="item?.original_url"
                                    alt="Preview"
                                    width="100"
                                    height="100"
                                  />
                                </template>
                                <template v-else>
                                  <svg
                                    width="100px"
                                    height="100px"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#000000"
                                  >
                                    <g
                                      id="SVGRepo_bgCarrier"
                                      stroke-width="0"
                                    ></g>
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                      <title></title>
                                      <g id="Complete">
                                        <g id="F-File">
                                          <g id="Text">
                                            <g>
                                              <path
                                                d="M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z"
                                                fill="none"
                                                id="File"
                                                stroke="#000000"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              ></path>
                                              <line
                                                fill="none"
                                                stroke="#000000"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                x1="7.9"
                                                x2="16.1"
                                                y1="17.5"
                                                y2="17.5"
                                              ></line>
                                              <line
                                                fill="none"
                                                stroke="#000000"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                x1="7.9"
                                                x2="16.1"
                                                y1="13.5"
                                                y2="13.5"
                                              ></line>
                                              <line
                                                fill="none"
                                                stroke="#000000"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                x1="8"
                                                x2="13"
                                                y1="9.5"
                                                y2="9.5"
                                              ></line>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                </template>
                              </div>
                            </b-col>
                            <b-col md="3" lg="3" xs="12">
                              <b-button
                                class="flex-shrink-0 btn-sm"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-danger"
                                @click="removePreviousFile(index)"
                              >
                                <feather-icon icon="XIcon" />
                              </b-button>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </template>

              <b-row>
                <b-col>
                  <div class="file-input">
                    <label for="file-upload" class="attach-icon">
                      Attach File
                      <feather-icon
                        icon="PaperclipIcon"
                        class="mr-50"
                        style="color: #7367f0"
                      />
                    </label>
                    <input
                      hidden
                      id="file-upload"
                      type="file"
                      multiple
                      @change="handleFileUpload"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="scrollable-file-container">
                    <div class="uploaded-files mb-2 scrollable-file-content">
                      <div
                        v-for="(file, index) in uploadedFiles"
                        :key="index"
                        class="file-preview"
                      >
                        <b-row class="mr-1">
                          <b-col md="8" lg="8" xs="12">
                            <div v-b-tooltip.hover.bottom="file.name">
                              <template v-if="isImage(file)">
                                <img
                                  :src="file.preview"
                                  alt="Preview"
                                  width="100"
                                  height="100"
                                />
                              </template>
                              <template v-else>
                                <svg
                                  width="100px"
                                  height="100px"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#000000"
                                >
                                  <g
                                    id="SVGRepo_bgCarrier"
                                    stroke-width="0"
                                  ></g>
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <title></title>
                                    <g id="Complete">
                                      <g id="F-File">
                                        <g id="Text">
                                          <g>
                                            <path
                                              d="M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z"
                                              fill="none"
                                              id="File"
                                              stroke="#000000"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              stroke-width="2"
                                            ></path>
                                            <line
                                              fill="none"
                                              stroke="#000000"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              stroke-width="2"
                                              x1="7.9"
                                              x2="16.1"
                                              y1="17.5"
                                              y2="17.5"
                                            ></line>
                                            <line
                                              fill="none"
                                              stroke="#000000"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              stroke-width="2"
                                              x1="7.9"
                                              x2="16.1"
                                              y1="13.5"
                                              y2="13.5"
                                            ></line>
                                            <line
                                              fill="none"
                                              stroke="#000000"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              stroke-width="2"
                                              x1="8"
                                              x2="13"
                                              y1="9.5"
                                              y2="9.5"
                                            ></line>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </template>
                            </div>
                          </b-col>
                          <b-col md="3" lg="3" xs="12">
                            <b-button
                              class="flex-shrink-0 btn-sm"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-danger"
                              @click="removeFile(index)"
                            >
                              <feather-icon icon="XIcon" />
                            </b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6" lg="6" xs="12">
                  <b-form-group label="Estimation Point" label-for="point">
                    <validation-provider
                      #default="{ errors }"
                      name="point"
                      vid="point"
                      rules="min_value:0|max_value:9999"
                    >
                      <b-form-input
                        id="point"
                        type="text"
                        v-model="point"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Estimation Point"
                        class="custom-font"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" lg="6" xs="12">
                  <b-form-group
                    label="Assigned Start Date"
                    label-for="start_date"
                  >
                    <ValidationProvider
                      name="start_date"
                      v-slot="{ errors }"
                      vid="start_date"
                    >
                      <b-form-datepicker
                        id="start_date"
                        v-model="startDate"
                        class="form-control custom-font"
                        :state="errors.length > 0 ? false : null"
                        locale="en-US"
                        today-button
                        close-button
                        reset-button
                        placeholder="Select Assigned Start Date "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" lg="6" xs="12">
                  <b-form-group
                    label="Actual Start Date"
                    label-for="actual_start_date"
                  >
                    <ValidationProvider
                      name="actual_start_date"
                      v-slot="{ errors }"
                      vid="actual_start_date"
                    >
                      <b-form-datepicker
                        id="actual_start_date"
                        v-model="actualStartDate"
                        class="form-control custom-font"
                        :state="errors.length > 0 ? false : null"
                        locale="en-US"
                        today-button
                        close-button
                        reset-button
                        placeholder="Actual Start Date "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col md="6" lg="6" xs="12">
                  <b-form-group
                    label="Assigned End Date"
                    label-for="assigned_end_date"
                  >
                    <ValidationProvider
                      name="assigned_end_date"
                      v-slot="{ errors }"
                      vid="assigned_end_date"
                    >
                      <b-form-datepicker
                        id="assigned_end_date"
                        v-model="assignedEndDate"
                        class="form-control custom-font"
                        :state="errors.length > 0 ? false : null"
                        locale="en-US"
                        today-button
                        close-button
                        reset-button
                        placeholder="Assigned End Date "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col md="6" lg="6" xs="12">
                  <b-form-group
                    label="Actual End Date"
                    label-for="actual_end_date"
                  >
                    <ValidationProvider
                      name="actual_end_date"
                      v-slot="{ errors }"
                      vid="actual_end_date"
                    >
                      <b-form-datepicker
                        id="actual_end_date"
                        v-model="actualEndDate"
                        class="form-control custom-font"
                        :state="errors.length > 0 ? false : null"
                        locale="en-US"
                        today-button
                        close-button
                        reset-button
                        placeholder="Actual End Date "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col md="6" lg="6" xs="12">
                  <b-form-group label="Word Count" label-for="wordcount">
                    <ValidationProvider
                      name="wordcount"
                      v-slot="{ errors }"
                      vid="wordcount"
                    >
                      <b-form-input
                        id="wordcount"
                        type="text"
                        class="custom-font"
                        v-model="wordcount"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter sub task Content Wordcount"
                        :disabled="TaskWordcount > 0"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                  <small v-if="TaskWordcount > 0" class="text-danger">Subtask word count not available when task has word count</small>
                </b-col>
                <b-col md="6" lg="6" xs="12">
                  <b-form-group label="Content Link" label-for="contentlink">
                    <ValidationProvider
                      name="contentlink"
                      v-slot="{ errors }"
                      vid="contentlink"
                    >
                      <b-form-input
                        id="contentlink"
                        type="text"
                        class="custom-font"
                        v-model="contentlink"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter sub task Content Content Link"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- loading button -->
              <template v-if="isLoading">
                <b-button class="float-right" variant="primary" disabled>
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>

              <!-- submit button -->
              <template v-else>
                <b-button
                  type="submit"
                  class="float-right mt-2"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                >
                  Submit
                </b-button>
              </template>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>

      <div>
        <b-modal
          id="modal-add-member-form"
          centered
          title="Add Member"
          hide-footer
          @hidden="hiddenAddMemberModal"
          no-close-on-backdrop
        >
          <validation-observer ref="addMemberValidation">
            <b-form v-on:submit.prevent="addMemberValidationForm">
              <!-- select member-->
              <b-form-group label="Member" label-for="user_id">
                <ValidationProvider
                  name="user_id"
                  v-slot="{ errors }"
                  vid="user_id"
                >
                  <v-select
                    id="user_id"
                    placeholder="Search by employee name or email"
                    v-model="selectMemberIds"
                    :filterable="false"
                    :options="memberIdOptions"
                    :reduce="(item) => item.id"
                    label="name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                  >
                    <template #option="data">
                      <UserSelect :user="data" />
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- loading button -->
              <template v-if="isLoading">
                <b-button class="float-right" variant="primary" disabled>
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>

              <!-- submit button -->
              <template v-else>
                <b-button
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                >
                  Add
                </b-button>
              </template>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
    </template>
  </section>
</template>
<!--  -->
<script>
import {
  BLink,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BButton,
  BForm,
  BModal,
  BCol,
  BRow,
  BCardText,
  BFormTextarea,
  BSpinner,
  BAlert,
  VBTooltip,
  BFormDatepicker,
  BTabs,
  BTab,
  BImg,
  BMedia,
  BAvatarGroup,
  BEmbed,
  BDropdownDivider,
  BButtonGroup,
  BListGroup,
  BListGroupItem,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import { VueGoodTable } from "vue-good-table";
import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, max, size, ext, max_value, min_value } from "@validations";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { kFormatter } from "@core/utils/filter";
import { priorityOptionConstants } from "@/helpers/constant/priorityOptionConstant";
import { taskSubtaskTypeOptionConstant } from "@/helpers/constant/taskSubtaskTypeOptionConstant";

import { MILESTONE_BOARD_SUB_TASK_EDIT } from "@/helpers/permissionsConstant";
import UserSelect from '@/layouts/components/UserSelect.vue'
import UserCard from '@/layouts/components/UserCard.vue'

export default {
  name: "SubTaskDetailsView",
  components: {
    UserCard,
    UserSelect,
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BRow,
    BCardText,
    BSpinner,
    BFormTextarea,
    flatPickr,
    BAlert,
    BFormDatepicker,
    BTabs,
    BTab,
    BImg,
    BMedia,
    AppTimeline,
    AppTimelineItem,
    BAvatarGroup,
    BEmbed,
    BDropdownDivider,
    quillEditor,
    BCardCode,
    BButtonGroup,
    BListGroup,
    BListGroupItem,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  methods: {
    kFormatter,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },

      MILESTONE_BOARD_SUB_TASK_EDIT,
      showCopiedMessage: false,

      //Phase Duration Log
      timeDurationLog: [],
      phaseChangeLog: [],
      nextPhaseChangeStartTime: [],

      //Members
      isSearchingMember: false,
      searchMember: "",
      selectMemberIds: [],
      searchMemberIdOptions: [],
      memberIdOptions: [],

      //phase update
      subTaskPhaseId: "",
      // subtask
      subtask: {},
      title: "",
      description: "",
      summary: "",
      priorityOptionConstants,
      taskSubtaskTypeOptionConstant,
      subtaskPhase: {},
      subtaskPhaseOptions: [],
      subtaskId: "",
      taskId: "",
      selectPhaseId: "",
      point: "",
      wordcount: "",
      TaskWordcount: 0,
      contentlink: "",
      startDate: "",
      actualStartDate: "",
      assignedEndDate: "",
      actualEndDate: "",
      selectMemberIds: [],
      memberIdOptions: [],
      uploadedFiles: [],
      subTaskFiles: [],
      previousFiles: [],
      isSubTaskLinkFormLoaded: false,

      // subtask link
      isSubTaskLinkLoading: false,
      sub_task_list_id: "",
      subtaskLinkList: [],
      subtasksLinkOption: [],

      //comment
      commentDescription: "",
      commentList: [],
      commentId: "",

      //activity log
      subTaskActivityLog: [],

      //toggling text editor
      taskEditClicked: false,
      isShowCommentModal: false,
      isShowEditCommentModal: false,
      editCommentModalIndex: "",
      isSubTaskDetailsPageLoading: false,
      detailedComments: [
        {
          username: "test user name",
          comment: "test comment",
        },
        {
          username: "test user name",
          comment: "test comment",
        },
      ],

      task: {},

      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser",
    }),
  },

  async created() {
    try {
      this.isSubTaskDetailsPageLoading = true;
      await this.loadItems();
      this.loadActivityLog()
    } catch (error) {
      if (error?.response?.data?.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    } finally {
      this.isSubTaskDetailsPageLoading = false;
    }
  },
  methods: {
    // format date
    formatDate(dateString) {
      if (dateString) {
        const date = new Date(dateString);
        const month = date.toLocaleString("default", { month: "short" });
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
      }
      return "";
    },

    // copy link
    copySubTaskUrlToClipboard() {
      const currentPageURL = window.location.href;

      navigator.clipboard
        .writeText(currentPageURL)
        .then(() => {
          this.showCopiedMessage = true;
          setTimeout(() => {
            this.showCopiedMessage = false;
          }, 2000); // Show the message for 2 seconds
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },

    /*** File Upload Start ***/
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!this.checkDuplicateFile(file)) {
          this.uploadedFiles.push({
            name: file.name,
            preview: URL.createObjectURL(file),
            type: file.type,
          });

          this.subTaskFiles.push(file);
        }
      }
    },
    checkDuplicateFile(file) {
      return this.uploadedFiles.some(
        (uploadedFile) => uploadedFile.name === file.name
      );
    },
    isImage(file) {
      return file.type.startsWith("image/");
    },

    removeFile(index) {
      this.uploadedFiles.splice(index, 1);
    },

    //previous file
    isPreviousFileImage(file) {
      return file.mime_type.startsWith("image/");
    },
    removePreviousFile(index) {
      this.previousFiles.splice(index, 1);
    },
    /*** File Upload End***/

    /** Toggling Text Editor For Comment Section Start*/

    onShowEditComment(index, value) {
      this.editCommentModalIndex = index;
      this.isShowEditCommentModal = true;
      this.isShowCommentModal = false;
      this.taskEditClicked = false;
      this.commentId = value?.id;
      this.commentDescription = value?.description;
    },
    hiddenEditCommentModal() {
      this.isShowEditCommentModal = false;
      this.editCommentModalIndex = "";
      this.resetComment();
    },

    showCommentModal() {
      this.isShowEditCommentModal = false;
      this.isShowCommentModal = true;
      this.taskEditClicked = false;
      this.resetComment();
    },
    hiddenCommentModal() {
      this.taskEditClicked = true;
      this.isShowCommentModal = false;
      this.resetComment();
    },
    showTaskModalToggleTextEditor() {
      this.taskEditClicked = true;
      this.isShowCommentModal = false;
      this.isShowEditCommentModal = false;
    },

    resetToogleVariable() {
      taskEditClicked = false;
      isShowCommentModal = false;
      isShowEditCommentModal = false;
      editCommentModalIndex = "";
    },
    /** Toggling Text Editor For Comment Section End */

    /** Sub Task Activity Log Section Start */

    setLogVariant(value) {
      const logColors = {
        created: "primary",
        updated: "warning",
        deleted: "danger",
      };
      return logColors[value?.event] || "warning";
    },
    formatLogTitle(value) {
      let logTitle = "";

      if (value?.event === "created") {
        if (value?.log_name == "sub_tasks") {
          logTitle = `Sub Task  <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.title} </span> was created `;
        }

        if (value?.log_name == "linked_tasks") {
          logTitle = `Sub Task  <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.task_subtask_title} </span> was linked with this Sub Task `;
        }

        if (value?.log_name == "comments") {
          logTitle = ` <span class="font-weight-bolder text-primary">${value?.properties?.attributes?.createdBy?.name} (${value?.properties?.attributes?.createdBy?.email}) </span> commented on this Sub Task `;
        }
      }

      if (value?.event === "updated") {
        if (value?.log_name == "sub_tasks") {
          logTitle = `Sub Task  <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.title}'s </span> information was updated`;
        }

        if (value?.log_name == "comments") {
          logTitle = ` <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.createdBy?.name} (${value?.properties?.attributes?.createdBy?.email}) </span> updated his previous comment on this Sub Task `;
        }
      }

      if (value?.event === "deleted") {
        if (value?.log_name == "linked_tasks") {
          logTitle = `Sub Task  <span class="font-weight-bolder text-danger">${value?.properties?.old?.task_subtask_title} </span> was unlinked from this Sub Task `;
        }

        if (value?.log_name == "comments") {
          logTitle = ` <span class="font-weight-bolder text-danger">${value?.properties?.old?.createdBy?.name} (${value?.properties?.old?.createdBy?.email}) </span> deleted his comment from this Sub Task `;
        }
      }

      if (value?.log_name === "default") {
        if (value?.description == "Sub Task Team Assign Activity Logged") {
          logTitle = `Sub Task <span class="font-weight-bolder text-warning"> Assigned Member </span> was updated for this Sub Task`;
        }
      }

      return logTitle;
    },
    formatLogDescription(value) {
      let description = "";
      if (value?.event != "created") {
        if (value?.event == "updated") {
          if (value?.log_name == "sub_tasks") {
            if (
              value?.properties?.old?.phase_id !=
              value?.properties?.attributes?.phase_id
            ) {
              description = `Phase updated from <span class="font-weight-bolder text-warning">${value?.properties?.old?.phase?.title} </span> to  <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.phase?.title} </span> <br>`;
            }

            if (
              value?.properties?.old?.priority !=
              value?.properties?.attributes?.priority
            ) {
              const oldPriorityName = this.priorityOptionConstants.reduce(
                (acc, option) => {
                  if (option.value === value?.properties?.old?.priority) {
                    return option.name;
                  }
                  return acc;
                },
                ""
              );

              const newPriorityName = this.priorityOptionConstants.reduce(
                (acc, option) => {
                  if (
                    option.value === value?.properties?.attributes?.priority
                  ) {
                    return option.name;
                  }
                  return acc;
                },
                ""
              );

              description =
                description +
                `Priority updated from <span class="font-weight-bolder text-warning">${oldPriorityName} </span> to  <span class="font-weight-bolder text-warning">${newPriorityName} </span>`;
            }
          }

          if (value?.log_name == "comments") {
            description = "[Previous]";
            description = description + value?.properties?.old?.description;

            description = description + "[Updated]";
            description =
              description + value?.properties?.attributes?.description;
          }
        }

        if (value?.event == "deleted") {
          if (value?.log_name == "comments") {
            description = "[Deleted]";
            description = description + value?.properties?.old?.description;
          }
        }

        if (value?.log_name === "default") {
          if (value?.description == "Sub Task Team Assign Activity Logged") {
            let removedMembers = value?.properties?.removed_members;

            if (removedMembers.length > 0) {
              for (let i = 0; i < removedMembers.length - 1; i++) {
                description =
                  description +
                  `<span class="font-weight-bolder text-danger">${removedMembers[i].name} (${removedMembers[i]?.email})</span>, `;
              }

              description =
                description +
                `<span class="font-weight-bolder text-danger">${
                  removedMembers[removedMembers.length - 1].name
                } (${
                  removedMembers[removedMembers.length - 1]?.email
                })</span> were removed from the Task. <br><br>`;
            }

            let addedMembers = value?.properties?.added_members;

            if (addedMembers.length > 0) {
              for (let i = 0; i < addedMembers.length - 1; i++) {
                description =
                  description +
                  `<span class="font-weight-bolder text-warning">${addedMembers[i].name} (${addedMembers[i]?.email})</span>, `;
              }

              description =
                description +
                `<span class="font-weight-bolder text-warning">${
                  addedMembers[addedMembers.length - 1].name
                } (${
                  addedMembers[addedMembers.length - 1]?.email
                })</span> were assigned to the Sub Task.`;
            }
          }
        }
      }

      if (value?.event == "created") {
        if (value?.log_name == "comments") {
          description = description + "[New Comment]";
          description =
            description + value?.properties?.attributes?.description;
        }
      }

      return description;
    },

    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YY, h:mm:ss a");
      }
    },

    /** Sub Task Activity Log Section End */

    /** Sub Task Phase Duration Log */

    formatPhaseLogTitle(value) {
      let logTitle = "";

      if (value?.event === "updated") {
        if (value?.log_name == "tasks") {
          if (
            value?.properties?.old?.phase_id !=
            value?.properties?.attributes?.phase_id
          ) {
            logTitle = `Task Moved To <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.phase?.title}  </span> Phase`;
          }
        }
        if (value?.log_name == "sub_tasks") {
          if (
            value?.properties?.old?.phase_id !=
            value?.properties?.attributes?.phase_id
          ) {
            logTitle = `Sub Task Moved To <span class="font-weight-bolder text-warning">${value?.properties?.attributes?.phase?.title}  </span> Phase`;
          }
        }
      } else if (value?.event === "deleted") {
        if (value?.log_name == "tasks") {
          logTitle = `Task  <span class="font-weight-bolder text-danger">${value?.properties?.old?.title} </span> was moved to Trash `;
        }
      }

      if (value?.log_name === "default") {
        if (value?.description == "Task Team Assign Activity Logged") {
          logTitle = `Task <span class="font-weight-bolder text-warning"> Assigned Member </span> was updated for <span class="font-weight-bolder text-primary"> ${value?.properties?.task?.title} </span> Task`;
        }
      }

      return logTitle;
    },

    formatPhaseLogDescription(createdAt, endedAt) {
      let description = "";

      if (createdAt && endedAt) {
        const createdTime = this.$moment(createdAt);
        const endedTime = this.$moment(endedAt);
        const duration = endedTime.diff(createdTime);
        const durationFormatted = this.$moment.duration(duration);

        const hours = durationFormatted.hours();
        const minutes = durationFormatted.minutes();
        const seconds = durationFormatted.seconds();

        description = "Phase Duration: ";
        if (hours) {
          description = description + ` ${hours} hours`;
        }

        if (minutes) {
          description = description + ` ${minutes} minutes`;
        }

        if (seconds) {
          description = description + ` ${seconds} seconds`;
        }
      }

      return description;

      // return description;
    },
    /** Sub Task Phase Duration Log End */

    onShowTaskDetails(task) {
      const id = this.subtask?.task_id;
      this.$router.push({
        name: "admin-task-board-task-details",
        params: {
          id,
          task,
        },
      });
    },
    onShowBoardDetails() {
      const id = this.subtask?.milestone_task_board_id;
      this.$router.push({
        name: "admin-task-board-details",
        params: { id },
      });
    },

    onShowPhase() {
      const id = this.subtask?.milestone_task_board_id;
      this.$router.push({
        name: "admin-task-board-phases",
        params: { id },
      });
    },

    async updatePhase(value) {
      try {
        const formData = new FormData();
        formData.append("_method", "PUT");

        this.subTaskPhaseId = value?.id;
        if (this.subTaskPhaseId) {
          formData.append("phase_id", this.subTaskPhaseId);
        }
        await this.$api.post(
          `api/milestone-task-boards/phases/tasks/subtasks/${this.$route.params.id}/update-phase`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.loadItems();
        this.loadTimeDurationLog();
        this.subTaskPhaseId = "";

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Sub Task Phase Updated Successfully",
          },
        });
      } catch (error) {
        if (error?.response?.data?.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }
      }
    },
    async getSubTaskInfo() {
      return await this.$api.get(
        `api/milestone-task-boards/phases/tasks/subtasks/${this.$route.params.id}?include=task,team,phase,comments.createdBy,milestoneTaskBoard`
      );
    },
    async getSubTaskActivityLog() {
      return await this.$api.get(
        `api/milestone-task-boards/phases/tasks/sub-tasks/${this.$route.params.id}/activitylog`
      );
    },

    async getBoardTimeDurationLog() {
      const milestoneTaskBoardId = this.subtask?.milestone_task_board_id;
      const subTaskId = this.$route.params.id;

      return await this.$api.get(
        `api/milestone-task-boards/${milestoneTaskBoardId}/time-duration`,
        {
          params: {
            filterSubTaskId: subTaskId,
          },
        }
      );
    },
    async loadItems() {
      try {
        const [subTaskInfo] = await Promise.all([this.getSubTaskInfo()]);

        this.subtask = subTaskInfo?.data?.data;

        this.subtaskLinkList = this.subtask?.links;

        this.commentList = this.subtask?.comments?.data.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        });

        const milestoneBoardInfo = await this.$api.get(
          `/api/milestone-task-boards/${this.subtask?.milestone_task_board_id}?include=phases`
        );

        const milestoneTaskBoardPhases = (
          milestoneBoardInfo?.data?.data?.phases?.data || []
        ).filter((item) => item?.is_backlog == false);

        this.subtaskPhaseOptions = (milestoneTaskBoardPhases || []).map(
          (item) => {
            return {
              id: item?.id,
              title: item?.title,
            };
          }
        );

        // All tasks for list
        const allSubTasks = await this.$api.get(
          `/api/milestone-task-boards/phases/tasks/${this.subtask?.task_id}/subtasks`
        );

        let subTaskOptions = (allSubTasks?.data?.data || []).filter(
          (item) => item?.id != this.$route.params.id
        );
        this.subtasksLinkOption = (subTaskOptions || []).map((item) => {
          let name = item.title;
          return {
            name,
            id: item.id,
          };
        });

        //Filtering Duplicate SubTasks
        this.subtasksLinkOption = this.subtasksLinkOption.filter((option) => {
          return !this.subtaskLinkList.some(
            (link) => link.task_subtask_title === option.name
          );
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async loadTimeDurationLog() {
      try {
        const [timeDurationLog] = await Promise.all([
          this.getBoardTimeDurationLog(),
        ]);

        this.nextPhaseChangeStartTime = [];
        this.phaseChangeLog = (timeDurationLog?.data?.data || []).filter(
          (item) =>
            item?.properties?.old?.phase_id !=
            item?.properties?.attributes?.phase_id
        );

        for (let i = 1; i < this.phaseChangeLog.length; i++) {
          this.nextPhaseChangeStartTime.push(this.phaseChangeLog[i].created_at);
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async loadActivityLog() {
      const [subTaskActivityLogs] = await Promise.all([
        this.getSubTaskActivityLog(),
      ]);

      this.subTaskActivityLog = subTaskActivityLogs?.data?.data;
    },
    onCommentDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This Comment?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(
              `api/milestone-task-boards/phases/tasks/sub-tasks/${this.$route.params.id}/comments/${id}`
            );
            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Comment Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    resetComment() {
      this.commentDescription = "";
      this.commentId = "";
    },
    showSubTaskLinkForm() {
      this.isSubTaskLinkFormLoaded = true;
    },
    closeSubTaskLinkForm() {
      this.isSubTaskLinkFormLoaded = false;
      this.sub_task_list_id = "";
    },

    async getMilestoneTaskBoardPhases() {
      const milestoneTaskBoardId = this.$route.params.id;

      return await this.$api.get(
        `/api/milestone-task-boards/${milestoneTaskBoardId}?include=phases`
      );
    },
    async getMilestoneTaskBoardPhases() {
      const milestoneTaskBoardId = this.$route.params.id;

      return await this.$api.get(
        `/api/milestone-task-boards/${milestoneTaskBoardId}?include=phases`
      );
    },
    onDeleteLinkSubTask(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Move This To Trash?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const subtaskid = this.$route.params.id;

            await this.$api.delete(
              `api/milestone-task-boards/phases/tasks/subtasks/${subtaskid}/links/${id}`
            );
            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "SUb Task Link Moved To Trash",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    // SubTask list to task page
    onShowSubTask(id) {
      const routeUrl = this.$router.resolve({
        name: "admin-task-board-task-subtask-details",
        params: { id },
      }).href;

      const newTab = window.open(routeUrl, "_blank");
      newTab.focus();
    },

    showSubTaskModal(value) {
      this.showTaskModalToggleTextEditor();
      this.subtaskId = value?.id;
      this.taskId = value?.task_id;
      this.title = value?.title;
      this.description = value?.description;
      this.summary = value?.summary;
      this.type = value?.type;
      this.selectPriorityType = value?.priority;
      this.point = value?.point;
      this.wordcount = value?.wordcount;
      this.TaskWordcount = value?.task?.data?.wordcount;
      this.contentlink = value?.contentlink;
      this.startDate = value?.start_date;
      this.actualStartDate = value?.actual_start_date;
      this.assignedEndDate = value?.assigned_end_date;
      this.actualEndDate = value?.actual_end_date;
      this.selectPhaseId = value?.phase_id;
      this.previousFiles = (value?.files || []).map((item) => item);

      this.$bvModal.show("modal-sub-task-board-phase-task");
    },
    hiddenSubTaskModal() {
      this.$bvModal.hide("modal-sub-task-board-phase-task");
      this.resetSubTaskModal();
    },
    resetSubTaskModal() {
      this.modelType = "";
      this.subtaskId = "";
      this.taskId = "";
      this.title = "";
      this.description = "";
      this.summary = "";
      this.selectPriorityType = "";
      this.priorityOptionConstants;
      this.point = "";
      this.startDate = "";
      this.actualStartDate = "";
      this.assignedEndDate = "";
      this.actualEndDate = "";
      this.uploadedFiles = [];
      this.subTaskFiles = [];
      this.previousFiles = [];
      this.sub_task_list_id = "";
    },
    async showAddMemberModal(value) {
      this.subtaskId = value?.id;

      const milestoneBoardInfo = await this.getMilestoneBoardTeam(
        value?.milestone_task_board_id
      );

      const teamMembers = milestoneBoardInfo?.data?.data?.team?.data;

      this.selectMemberIds = (value?.team?.data).map((item) => item?.id);
      this.memberIdOptions = teamMembers.map((item) => {
        return {
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }
      });
      this.$bvModal.show("modal-add-member-form");
    },

    async getMilestoneBoardTeam(milestoneTaskBoardId) {
      return await this.$api.get(
        `/api/milestone-task-boards/${milestoneTaskBoardId}?include=team`
      );
    },

    hiddenAddMemberModal() {
      this.$bvModal.hide("modal-add-member-form");
      this.resetAddMemberModal();
    },
    resetAddMemberModal() {
      this.selectMemberIds = [];
    },

    subtaskEditForm: async function () {
      this.$refs.subtaskEdit.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.subtaskEdit.reset();
            this.isLoading = true;
            const formData = new FormData();

            formData.append("_method", "PUT");
            if (this.title) {
              formData.append("title", this.title);
            }

            if (this.description) {
              formData.append("description", this.description);
            } else {
              formData.append("description", "");
            }

            if (this.summary) {
              formData.append("summary", this.summary);
            } else {
              formData.append("summary", "");
            }

            if (this.type) {
              formData.append("type", this.type);
            } else {
              formData.append("type", "");
            }

            if (this.selectPriorityType) {
              formData.append("priority", this.selectPriorityType);
            } else {
              formData.append("priority", "");
            }

            if (this.point) {
              formData.append("point", this.point);
            } else {
              formData.append("point", "");
            }

            if (this.wordcount) {
              formData.append("wordcount", this.wordcount);
            } else {
              formData.append("wordcount", "");
            }

            if (this.contentlink) {
              formData.append("contentlink", this.contentlink);
            } else {
              formData.append("contentlink", "");
            }

            if (this.startDate) {
              formData.append("start_date", this.startDate);
            } else {
              formData.append("start_date", "");
            }

            if (this.actualStartDate) {
              formData.append("actual_start_date", this.actualStartDate);
            } else {
              formData.append("actual_start_date", "");
            }

            if (this.assignedEndDate) {
              formData.append("assigned_end_date", this.assignedEndDate);
            } else {
              formData.append("assigned_end_date", "");
            }

            if (this.actualEndDate) {
              formData.append("actual_end_date", this.actualEndDate);
            } else {
              formData.append("actual_end_date", "");
            }

            this.previousFiles.forEach(function (element) {
              formData.append("previous_files[]", element.id);
            });
            this.subTaskFiles.forEach(function (element) {
              formData.append("files[]", element);
            });

            await this.$api.post(
              `/api/milestone-task-boards/phases/tasks/${this.taskId}/subtasks/${this.subtaskId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            this.isLoading = false;
            this.loadItems();
            this.loadActivityLog();
            this.hiddenSubTaskModal();
            this.resetSubTaskModal();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Task Information Updated Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.subtaskEdit.setErrors(error?.response?.data?.errors);
            }
          }
        }
      });
    },

    subTaskLinkAddForm: async function () {
      this.$refs.subTaskLinkAdd.validate().then(async (success) => {
        if (success) {
          try {
            this.isSubTaskLinkLoading = true;

            await this.$api.post(
              `/api/milestone-task-boards/phases/tasks/subtasks/${this.$route.params.id}/links`,

              {
                task_subtask_id: this.sub_task_list_id?.id,
                task_subtask_title: this.sub_task_list_id?.name,
              }
            );

            this.isSubTaskLinkLoading = false;
            this.loadItems();
            this.closeSubTaskLinkForm();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Sub Task Link Added Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.backlogTask.setErrors(error?.response?.data?.errors);
            }
          }
        }
      });
    },

    addMemberValidationForm: async function () {
      this.$refs.addMemberValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.addMemberValidation.reset();
            this.isLoading = true;

            await this.$api.post(
              `api/milestone-task-boards/tasks/subtasks/${this.$route.params.id}/assign-team`,
              {
                user_ids: this.selectMemberIds,
              }
            );
            this.isLoading = false;
            this.loadItems();
            this.loadActivityLog();

            this.hiddenAddMemberModal();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Member Added Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
            if (error?.response?.data?.errors) {
              this.$refs.milestoneTaskBoardValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    addCommentForm: async function () {
      this.$refs.addCommentFormValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            await this.$api.post(
              `api/milestone-task-boards/phases/tasks/sub-tasks/${this.$route.params.id}/comments`,
              {
                description: this.commentDescription,
              }
            );
            this.isLoading = false;
            this.loadItems();
            this.loadActivityLog();
            this.hiddenCommentModal();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Comment Posted Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
            if (error?.response?.data?.errors) {
              this.$refs.milestoneTaskBoardValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    editCommentForm: async function () {
      for (let i = 0; i < this.$refs.editCommentFormValidation.length; i++) {
        this.$refs.editCommentFormValidation[i]
          .validate()
          .then(async (success) => {
            if (success) {
              try {
                this.isLoading = true;
                await this.$api.put(
                  `api/milestone-task-boards/phases/tasks/sub-tasks/${this.$route.params.id}/comments/${this.commentId}`,
                  {
                    description: this.commentDescription,
                  }
                );
                this.isLoading = false;
                this.loadItems();
                this.loadActivityLog();
                this.hiddenEditCommentModal();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Comment Edited Successfully",
                  },
                });
              } catch (error) {
                this.isLoading = false;
                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }
                if (error?.response?.data?.errors) {
                  this.$refs.milestoneTaskBoardValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";

.custom-font {
  font-size: 11px !important;
}

.label-margin {
  margin-top: 8px;
  margin-right: 8px;
}

.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.custom-font {
  font-size: 13px !important;
}

.file-input {
  display: inline-block;
  position: relative;
}

.attach-icon {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.uploaded-files {
  margin-top: 20px;
}

.file-preview {
  display: inline-block;
  margin-right: 10px;
}
.file-preview .bigger-icon {
  font-size: 50px;
  color: #999;
}
.button-padding {
  padding-bottom: 15px;
}
</style>
